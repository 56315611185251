import React, { useEffect, useState } from 'react';
import CommonLayout from '../../../layouts/CommonLayout';
import './QuizList.css'; // Import your CSS file
import { allAPiServicesCall } from '../../../services/apiServices';
import { getAuthConfig } from '../../../services/apiUtils';
import { useNavigate } from 'react-router-dom';

const QuizList = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [genres, setGenres] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState('all');
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    const [hasAnswered, setHasAnswered] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        const fetchGenres = async () => {
            try {
                const response = await allAPiServicesCall.getGenres(getAuthConfig())
                if (response && response.status == 200) {
                    setGenres(response.data);
                }
            } catch (error) {
                console.error('Error fetching genres:', error);
            }
        };

        fetchGenres();
    }, []);

    useEffect(() => {
        const fetchQuizzes = async () => {
            try {
                const response = await allAPiServicesCall.getAllQuizs(getAuthConfig(), { genre: selectedGenre, page: 1 }, navigate)
                if (response && response.status == 200) {
                    setQuizzes(response.data.quizzes);
                }
            } catch (error) {
                console.error('Error fetching quizzes:', error);
            }
        };

        fetchQuizzes();
    }, [selectedGenre]);

    const handleAnswerClick = (quizId, option) => {
        const quiz = quizzes.find(q => q._id === quizId);
        const isCorrect = option === quiz.correctAnswer;

        setAnsweredQuestions(prev => ({
            ...prev,
            [quizId]: { selectedOption: option, isCorrect }
        }));

        setHasAnswered(prev => ({
            ...prev,
            [quizId]: true
        }));
    };

    return (
        <CommonLayout>
            <h2 style={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.8rem",
            fontWeight: "600",
            letterSpacing: "1.5px",
            textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
            marginBottom: "1%",
            background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
            WebkitBackgroundClip: "text",
          }} className='pt-5' >Quiz Questions</h2>

            <div className="genre-selector px-1">
                <label className='fs-3'>Select Genre: </label>
                <select onChange={(e) => setSelectedGenre(e.target.value)} value={selectedGenre} className='form-select form-select-lg mb-3'>
                    <option value="all">All</option>
                    {genres.map((genre) => (
                        <option key={genre._id} value={genre.genreName}>
                            {genre.genreName}
                        </option>
                    ))}
                </select>
            </div>
            <div className="quiz-container pb-5 mb-5 px-1">
                {quizzes.map((quiz) => (
                    <div key={quiz._id} className="quiz-card">
                        <h3>{quiz.question}</h3>
                        <div className="options-container">
                            {['A', 'B', 'C', 'D'].map((optionKey) => {
                                const isSelected = answeredQuestions[quiz._id]?.selectedOption === optionKey;
                                const isCorrect = answeredQuestions[quiz._id]?.isCorrect;
                                const isRightAnswer = quiz.correctAnswer === optionKey;

                                let buttonClass = 'option-button';
                                if (isSelected) {
                                    buttonClass += isCorrect ? ' correct' : ' incorrect';
                                } else if (hasAnswered[quiz._id] && isRightAnswer) {
                                    buttonClass += ' correct'; // Show correct answer after answering
                                }

                                return (
                                    <button
                                        key={optionKey}
                                        onClick={() => handleAnswerClick(quiz._id, optionKey)}
                                        className={buttonClass}
                                    >
                                        {quiz[`option${optionKey}`]}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </CommonLayout>
    );
};

export default QuizList;