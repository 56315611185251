import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Container, Row, Col, Image, Spinner } from "react-bootstrap";
import CommonLayout from "../../../layouts/CommonLayout";
import { useNavigate } from "react-router-dom";


const ArtistAlbumsAndsongs = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    "http://localhost:3007/api/v1/admin/get-artist-songs-albums?artist_id=4",
                    {
                        headers: {
                            Authorization:
                                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NjU4NzMwNGU1ZGMxZDUzMjFmNmNhYzkiLCJlbWFpbCI6ImFkbWlubHdAeW9wbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MzIyNTk4MDMsImV4cCI6MTczMjM0NjIwM30._2JCNUQX29Nqtf868xC40Z4JVmGikpd2TJqmSbYrn-U",
                        },
                    }
                );
                setData(response.data.data[0]);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch data");
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <div className="text-center mt-5">
                <Spinner animation="border" />
            </div>
        );
    }

    if (error) {
        return <div className="text-center text-danger mt-5">{error}</div>;
    }

    return (
        <CommonLayout>
            <Container className="mt-4">
                {/* Artist Details */}
                {/* <h4 className="mb-3 text-center">Artist Details</h4>
                <Card className="mb-4">
                    <Card.Body className="text-center">
                        <Image
                            src={data.artist_image_link}
                            roundedCircle
                            width={150}
                            height={150}
                            className="mb-3"
                        />
                        <Card.Title>{data.artist_name}</Card.Title>
                    </Card.Body>
                </Card> */}

                {/* Albums */}
                <hr />
                <h4 className="mb-3 text-center">Albums</h4>
                <Row className="mb-4">
                    {data.albums.map((album) => (
                        <Col key={album.album_id} sm={6} md={4} lg={3} className="mb-3">
                            <Card onClick={() => navigate(`/album/songs/${album.album_id}`)} style={{ cursor: 'pointer' }}>
                                <Card.Img variant="top" src={album.image_link} />
                                <Card.Body>
                                    <Card.Title className="text-truncate">{album.album_name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                {/* Songs */}
                <hr />
                <h4 className="mb-3 text-center my-4">Songs</h4>
                <Row>
                    {data.songs.map((song) => (
                        <Col key={song.song_id} sm={6} md={4} lg={3} className="mb-3">
                            <Card onClick={() => navigate(`/lyrics/${song.isrc_code}`)}
                                style={{ cursor: 'pointer' }}>
                                <Card.Img
                                    variant="top"
                                    src={song.song_image_link !== "Error fetching image" ? song.song_image_link : "https://via.placeholder.com/150"}
                                />
                                <Card.Body>
                                    <Card.Title className="text-truncate">{song.song_name}</Card.Title>
                                    <Card.Text>
                                        <strong>Genre:</strong> {song.genre || "N/A"}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </CommonLayout>
    );
};

export default ArtistAlbumsAndsongs;
