

import React from "react";
import { Dropdown } from "react-bootstrap";
import {
  FaTachometerAlt,
  FaUser,
  FaMusic,
  FaCompactDisc,
  FaEllipsisH,
  FaListUl,
  FaQuestionCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../routes/constant";
import "bootstrap/dist/css/bootstrap.min.css";

const Sidebar = ({ isCollapsed, isMobileOpen, onClose }) => {

  return (
    <div style={{ height: "100%", backgroundColor: "#f0f0f0", zIndex: 1000 }}>
      <nav
        className={` vh-100 ${isCollapsed ? "collapsed" : ""} ${isMobileOpen ? "show" : ""
          }`}
        style={{
          zIndex: 1000,
          position: isMobileOpen ? "fixed" : "fixed",
          width: isMobileOpen || isCollapsed ? "200px" : "0px",
          height: "100%",
          transition: "width 0.5s, opacity 0.3s",
          opacity: isMobileOpen || isCollapsed ? 1 : 0,
          display: isMobileOpen || isCollapsed ? "block" : "none",
          backgroundColor: "#ececec",
        }}
      >
        <div className="sidebar-header p-3 d-flex justify-content-between align-items-center">
          <span style={{ fontSize: "20px", color: "blue" }}>Lyrics Web</span>
          {isMobileOpen && (
            <button className="btn-close" onClick={onClose} aria-label="Close">
              &times;
            </button>
          )}
        </div>

        <div
          className={`d-flex flex-column my-4 g-4 ${isCollapsed ? "collapsed" : ""
            }`}
        >
          {/* Dashboard */}
          <Link
            to="/"
            className="m-3 d-flex align-items-center text-decoration-none text-dark"
          >
            {/* {isCollapsed ? (
            <FaTachometerAlt size={24} />
          ) : (
            <>
              <FaTachometerAlt className="me-2" /> Dashboard
            </>
          )} */}
            <FaTachometerAlt className="me-2" size={20} /> Dashboard
          </Link>

          {/* Artist Dropdown */}
          <Dropdown className="m-3">
            <Dropdown.Toggle
              variant="light"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "black"
              }}
            >
              <FaUser className="me-2" size={20} /> Artist
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to="/trending-artist"
                className="text-dark"
              >
                Trending Artist
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/recommended-artist"
                className="text-dark"
              >
                Recommended Artist
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/top-artist"
                className="text-dark"
              >
                Top Artist
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Album Dropdown */}
          <Dropdown className="m-3">
            <Dropdown.Toggle
              variant="light"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "black"
              }}
            >
              <FaCompactDisc className="me-2" size={20} /> Album
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={ROUTE_CONSTANT.COMMON.TOP_EXPLORE}
                className="text-dark"
              >
                Top Explore
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Song Dropdown */}
          <Dropdown className="m-3">
            <Dropdown.Toggle
              variant="light"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "black"
              }}
            >
              <FaMusic className="me-2" size={20} /> Song
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={ROUTE_CONSTANT.COMMON.MOST_PLAYED_SONG}
                className="text-dark"
              >
                Most Played Song
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={ROUTE_CONSTANT.COMMON.RECOMMENDED_SONGS}
                className="text-dark"
              >
                Recommended Song
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/popular_songs" className="text-dark">
                Most Popular Song
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/top-songs" className="text-dark">
                Top Song
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Playlist Dropdown */}
          <Dropdown className="m-3">
            <Dropdown.Toggle
              variant="light"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "black"
              }}
            >
              <FaListUl className="me-2" size={20} /> Playlist
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={ROUTE_CONSTANT.COMMON.CREATE_PLAYLIST}
                className="text-dark"
              >
                Add Playlist
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={ROUTE_CONSTANT.COMMON.PLAYLIST}
                className="text-dark"
              >
                Get Playlist
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="m-3">
            <Dropdown.Toggle
              variant="light"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "black"
              }}
            >
              <FaQuestionCircle className="me-2" size={20} />
              Quiz
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={ROUTE_CONSTANT.COMMON.QUIZ}
                className="text-dark"
              >
                Quiz List
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={ROUTE_CONSTANT.COMMON.ADD_QUIZ}
                className="text-dark"
              >
                Add Quiz
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <Dropdown className="m- ">
            <Dropdown.Toggle
              variant="light"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "black"
              }}
            >
              <FaEllipsisH className="me-2" size={20} /> Other
            </Dropdown.Toggle>

            <Dropdown.Menu className="custom-scroll">
           
                   <Dropdown.Item as={Link} to="/artist-bio" className="text-dark">
               Artist Bio
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/news" className="text-dark">
                News
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/contribute-to-lyrics" className="text-dark">
                Contribute to Lyris
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/contact-us" className="text-dark">
                Contact Us
              </Dropdown.Item>
         
            </Dropdown.Menu>
          </Dropdown> */}
          <Dropdown className="m-3" drop="up">
            <Dropdown.Toggle
              variant="light"
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "black"
              }}
            >
              <FaEllipsisH className="me-2" size={20} /> Other
            </Dropdown.Toggle>

            <Dropdown.Menu className="custom-scroll" style={{
              overflow: "scroll"
            }}>
              <Dropdown.Item as={Link} to="/artist-bio" className="text-dark">
                Artist Bio
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/news" className="text-dark">
                News
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/contribute-to-lyrics" className="text-dark">
                Contribute to Lyrics
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/contact-us" className="text-dark">
                Contact Us
              </Dropdown.Item>
              {/* Add more items if needed */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
