import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { toast } from "react-toastify";
import { getAuthConfig } from "../../../services/apiUtils";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"; // Assuming it's in the same folder

const TopExplore = () => {
  const navigate = useNavigate();
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [albumToDelete, setAlbumToDelete] = useState(null);

  const fetchTopExploreAlbums = async () => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getTopExploreAlbums(getAuthConfig());
      if (response && response?.success) {
        setAlbums(response?.data?.albums);
      } else {
        toast.warn("No data found or invalid response.");
      }
    } catch (error) {
      console.error("Error fetching top explore albums:", error);
      toast.error("Failed to fetch top explore albums.");
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchResults = async (album_name) => {
    setLoading(true);
    try {
      let response = await allAPiServicesCall.getSearchAlbum(
        { album_name },
        getAuthConfig()
      );
      const searchList = response.data || [];
      if (searchList.length > 0) {
        setSearchResults(searchList);
      } else {
        setSearchResults([]);
        toast.info("No results found");
      }
    } catch (error) {
      console.error("Error fetching search artist:", error);
      toast.error("Failed to fetch search artists");
    } finally {
      setLoading(false);
    }
  };

  const addAlbumToTop = async (albumId) => {
    setLoading(true);
    try {
      const payload = { album_id: albumId };
      let response = await allAPiServicesCall.addTopAlbum(payload, getAuthConfig(), navigate);

      if (response && response.success) {
        setSearchTerm("");
        fetchTopExploreAlbums();
      }
    } catch (error) {
      console.error("Error adding top album:", error);
      toast.error("Failed to add top album.");
    } finally {
      setLoading(false);
    }
  };

  const handleOnDeleteClick = (albumId) => {
    setAlbumToDelete(albumId); // Store the album to delete
    setShowModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    setShowModal(false); // Close the modal
    try {
      let payload = { id: albumToDelete };
      await allAPiServicesCall.deleteTopExploreAlbum(payload, getAuthConfig());
      toast.success("Album deleted successfully!");
      fetchTopExploreAlbums();
    } catch (error) {
      console.error("There was an error deleting the album!", error);
      toast.error("Failed to delete album.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!searchTerm.trim()) {
      fetchTopExploreAlbums();
    }
  }, []);

  useEffect(() => {
    if (searchTerm.trim()) {
      fetchSearchResults(searchTerm);
    } else {
      fetchTopExploreAlbums();
    }
  }, [searchTerm]);

  const filteredAlbums = searchTerm.trim() ? searchResults : albums;

  return (
    <CommonLayout>
      <Container className="my-5 px-4">
        <Row className="mb-4">
          <Col md={8} className="mt-5">
            <Form.Control
              type="text"
              placeholder="Search Album to add to top explore"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-100 mb-3"
            />
          </Col>
        </Row>

        <Row className="mb-4 text-center">
          <Col>
            <h1 style={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.8rem",
            fontWeight: "600",
            letterSpacing: "1.5px",
            textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
            marginBottom: "1%",
            background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
            WebkitBackgroundClip: "text",
          }} className="display-4">Top Explore Albums</h1>
            <p className="lead ">Explore top albums and artists with their details</p>
          </Col>
        </Row>

        {loading ? (
          <Row>
            <Col>
              <p className="text-center">Loading...</p>
            </Col>
          </Row>
        ) : filteredAlbums.length > 0 ? (
          // <Row>
          //   {filteredAlbums.map((album) => (
          //     <Col md={3} key={album._id} className="mb-4">
          //       <Card
          //         style={{
          //           borderRadius: "10px",
          //           overflow: "hidden",
          //           transition: "transform 0.3s ease",
          //           height:"350px",
          //           width:"270px" 
          //         }}
          //         className="hover-shadow"
          //         onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
          //         onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          //       >
          //         <Card.Img
          //           variant="top"
          //           src={album.image_link || "default-image-url.jpg"}
          //           alt="Album cover"
          //           style={{ height: "150px", objectFit: "cover" }}
          //         />
          //         <Card.Body className="text-center" >
          //           {/* <Card.Title>{album.album_name || "No Album Name"}</Card.Title> */}
          //                   <ReadMoreText text={album.album_name || "No Album Name"} />
          //           <Card.Text>
          //             <strong>Artist:</strong> {album.artist_details.artist_name || "N/A"}

              
          //             <br />
          //           </Card.Text>

          //           <div className="d-flex justify-content-center ">
          //             {searchTerm.trim() ? (
          //               // Show single button for search results
          //               <Button
          //                 variant="primary"
          //                 className="me-2"
          //                 style={{ transition: "background-color 0.3s ease" }}
          //                 onClick={() => addAlbumToTop(album.album_id)}
          //               >
          //                 Add To Top Explore Album
          //               </Button>
          //             ) : (
          //               // Show two buttons for main list
          //               <>
          //                 <Button
          //                   variant="primary rounded-pill"
          //                   className="me-2"
          //                   style={{ transition: "background-color 0.3s ease",   whiteSpace: "nowrap", }}
          //                   onClick={() => navigate(`/album/songs/${album.album_id}`)}
          //                 >
          //                   View Songs
          //                 </Button>
          //                 <Button
          //                   variant="danger rounded-pill"
          //                   style={{ transition: "background-color 0.3s ease",   whiteSpace: "nowrap", }}
          //                   onClick={() => handleOnDeleteClick(album._id)}
          //                 >
          //                   Delete Album
          //                 </Button>
          //               </>
          //             )}
          //           </div>
          //         </Card.Body>
          //       </Card>
          //     </Col>
          //   ))}
          // </Row>
          <Row className="justify-content-center">
  {filteredAlbums.map((album) => (
    <Col
      xs={12}
      sm={6}
      md={4}
      lg={3}
      key={album._id}
      className="d-flex justify-content-center mb-4"
    >
      <Card
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          transition: "transform 0.3s ease",
          height: "350px",
          width: "100%",
          maxWidth: "320px",
        }}
        className="hover-shadow"
        onMouseEnter={(e) =>
          (e.currentTarget.style.transform = "scale(1.05)")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.transform = "scale(1)")
        }
      >
        <Card.Img
          variant="top"
          src={album.image_link || "default-image-url.jpg"}
          alt="Album cover"
          style={{ height: "150px", objectFit: "cover" }}
        />
        <Card.Body className="text-center">
          <ReadMoreText text={album.album_name || "No Album Name"} />
          <Card.Text>
            <strong>Artist:</strong>{" "}
            {album.artist_details.artist_name || "N/A"}
            <br />
          </Card.Text>

          <div className="d-flex justify-content-center">
            {searchTerm.trim() ? (
              // Show single button for search results
              <Button
                variant="primary"
                className="me-2"
                style={{ transition: "background-color 0.3s ease" }}
                onClick={() => addAlbumToTop(album.album_id)}
              >
                Add To Top Explore Album
              </Button>
            ) : (
              // Show two buttons for main list
              <>
                <Button
                  variant="primary rounded-pill"
                  className="me-2"
                  style={{
                    transition: "background-color 0.3s ease",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() =>
                    navigate(`/album/songs/${album.album_id}`)
                  }
                >
                  View Songs
                </Button>
                <Button
                  variant="danger rounded-pill"
                  style={{
                    transition: "background-color 0.3s ease",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => handleOnDeleteClick(album._id)}
                >
                  Delete Album
                </Button>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  ))}
</Row>

        ) : (
          <Row>
            <Col>
              <p className="text-center">No albums match your search.</p>
            </Col>
          </Row>
        )}

        {/* Confirmation Modal */}
        <ConfirmationModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleConfirm={handleConfirmDelete}
          message="Are you sure you want to delete this album?"
        />
      </Container>
    </CommonLayout>
  );

};


//read more text
const ReadMoreText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 20; // Maximum characters before truncating
  const truncatedText = text.length > maxLength && !isExpanded
    ? `${text.slice(9, maxLength)}..`
    : text;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card.Title>
      {truncatedText}
      {text.length > maxLength && (
        <span className="fs-6"
          style={{ color: "blue", cursor: "pointer", marginLeft: "3px",whiteSpace:"no-wrap" }}
          onClick={toggleExpanded}
        >
          {isExpanded ? "read less" : "Read more"}
        </span>
      )}
    </Card.Title>
  );
};





export default TopExplore;
