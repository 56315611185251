// import React, { useState, useEffect } from "react";
// import {
//   MDBContainer,
//   MDBRow,
//   MDBCol,
//   MDBCard,
//   MDBCardBody,
//   MDBCardText,+
//   MDBInput,
//   MDBCardImage,
// } from "mdb-react-ui-kit";
// import CommonLayout from "../../../layouts/CommonLayout";
// import { allAPiServicesCall } from "../../../services/apiServices";
// import { getAuthConfig } from "../../../services/apiUtils";
// import { useNavigate } from "react-router-dom";
// import { ROUTE_CONSTANT } from "../../../routes/constant";
// import { Button, Spinner } from "react-bootstrap";

// function EditProfile() {
//   const [profileData, setProfileData] = useState({
//     fullName: "",
//     email: "",
//     mobile: "",
//   });
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   const fetchUserData = async () => {
//     setLoading(true);
//     try {
//       const response = await allAPiServicesCall.getProfileDetails(
//         {},
//         getAuthConfig(),
//         navigate
//       );
//       if (response.success) {
//         setProfileData(response.data);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({ ...profileData, [name]: value });
//   };

//   const handleOnSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const response = await allAPiServicesCall.editProfile(
//         profileData,
//         getAuthConfig(),
//         navigate
//       );
//       if (response.success) {
//         navigate(ROUTE_CONSTANT.AUTH.SHOW_PROFILE);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <CommonLayout>
//       {loading ? (
//         <div className="d-flex justify-content-center align-items-center vh-100">
//           <Spinner animation="border" variant="primary" />
//         </div>
//       ) : (
//         <section style={{ backgroundColor: "#f8f9fa", paddingTop: "30px" }}>
//           {/* <MDBContainer className="py-5">
//             <MDBRow>
//               <MDBCol lg="4">
//                 <MDBCard className="mb-4 shadow-sm">
//                   <MDBCardBody className="text-center">
//                     <MDBCardImage
//                       src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
//                       alt="avatar"
//                       className="rounded-circle mb-3"
//                       style={{ width: "150px", border: "2px solid #007bff" }}
//                       fluid
//                     />
//                     <h5 className="mt-2">Admin</h5>
//                     <p className="text-muted mb-1">
//                       <a
//                         href="https://lyricsweb.com"
//                         style={{ textDecoration: "none", color: "#007bff" }}
//                       >
//                         Owner at Lyricsweb.com
//                       </a>
//                     </p>
//                   </MDBCardBody>
//                 </MDBCard>
//               </MDBCol>

//               <MDBCol lg="4" className="w-90">
//                 <MDBCard className="mb-4 shadow-sm ">
//                   <MDBCardBody>
//                     <form>
//                       <MDBRow className="align-items-center mb-3">
//                         <MDBCol sm="3">
//                           <MDBCardText>Full Name</MDBCardText>
//                         </MDBCol>
//                         <MDBCol sm="9">
//                           <MDBInput
//                             name="fullName"
//                             value={profileData.fullName}
//                             onChange={handleChange}
//                             placeholder="Enter full name"
//                             required
//                           />
//                         </MDBCol>
//                       </MDBRow>

//                       <hr />

//                       <MDBRow className="align-items-center mb-3">
//                         <MDBCol sm="3">
//                           <MDBCardText>Email</MDBCardText>
//                         </MDBCol>
//                         <MDBCol sm="9">
//                           <MDBInput
//                             type="email"
//                             name="email"
//                             value={profileData.email}
//                             readOnly
//                             onChange={handleChange}
//                             placeholder="Email"
//                             required
//                           />
//                         </MDBCol>
//                       </MDBRow>

//                       <hr />

//                       <MDBRow className="align-items-center mb-3">
//                         <MDBCol sm="3">
//                           <MDBCardText>Mobile</MDBCardText>
//                         </MDBCol>
//                         <MDBCol sm="9">
//                           <MDBInput
//                             name="mobile"
//                             value={profileData.mobile}
//                             onChange={handleChange}
//                             placeholder="Enter mobile number"
//                             required
//                           />
//                         </MDBCol>
//                       </MDBRow>

//                       <hr />

//                       <div className="d-flex justify-content-center">
//                         <Button
//                           variant="primary"
//                           className="w-100"
//                           onClick={handleOnSubmit}
//                           style={{
//                             transition: "transform 0.2s",
//                           }}
//                           onMouseEnter={(e) => {
//                             e.target.style.transform = "scale(1.05)";
//                           }}
//                           onMouseLeave={(e) => {
//                             e.target.style.transform = "scale(1)";
//                           }}
//                         >
//                           Save Changes
//                         </Button>
//                       </div>
//                     </form>
//                   </MDBCardBody>
//                 </MDBCard>
//               </MDBCol>
//             </MDBRow>
//           </MDBContainer> */}

//           <MDBContainer className="py-5">
//             <MDBRow>
//               <MDBCol lg="4">
//                 <MDBCard className="mb-4 shadow-sm">
//                   <MDBCardBody className="text-center">
//                     <MDBCardImage
//                       src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
//                       alt="avatar"
//                       className="rounded-circle mb-3"
//                       style={{ width: "150px", border: "2px solid #007bff" }}
//                       fluid
//                     />
//                     <h5 className="mt-2">Admin</h5>
//                     <p className="text-muted mb-1">
//                       <a
//                         href="https://lyricsweb.com"
//                         style={{ textDecoration: "none", color: "#007bff" }}
//                       >
//                         Owner at Lyricsweb.com
//                       </a>
//                     </p>
//                   </MDBCardBody>
//                 </MDBCard>
//               </MDBCol>

//               <MDBCol lg="8" className="mx-auto">
//                 {" "}
//                 {/* Increased form width */}
//                 <MDBCard
//                   className="shadow-sm mb-2"
//                   style={{ maxHeight: "500px", width: "700px" }}
//                 >
//                   {" "}
//                   <MDBCardBody>
//                     <form>
//                       <MDBRow className="align-items-center mb-4">
//                         <MDBCol sm="3">
//                           <MDBCardText>Full Name</MDBCardText>
//                         </MDBCol>
//                         <MDBCol sm="9">
//                           <MDBInput
//                             name="fullName"
//                             value={profileData.fullName}
//                             onChange={handleChange}
//                             placeholder="Enter full name"
//                             required
//                           />
//                         </MDBCol>
//                       </MDBRow>

//                       <hr />

//                       <MDBRow className="align-items-center mb-4">
//                         <MDBCol sm="3">
//                           <MDBCardText>Email</MDBCardText>
//                         </MDBCol>
//                         <MDBCol sm="9">
//                           <MDBInput
//                             type="email"
//                             name="email"
//                             value={profileData.email}
//                             readOnly
//                             onChange={handleChange}
//                             placeholder="Email"
//                             required
//                           />
//                         </MDBCol>
//                       </MDBRow>

//                       <hr />

//                       <MDBRow className="align-items-center mb-4">
//                         <MDBCol sm="3">
//                           <MDBCardText>Mobile</MDBCardText>
//                         </MDBCol>
//                         <MDBCol sm="9">
//                           <MDBInput
//                             name="mobile"
//                             value={profileData.mobile}
//                             onChange={handleChange}
//                             placeholder="Enter mobile number"
//                             required
//                           />
//                         </MDBCol>
//                       </MDBRow>
//                     </form>
//                   </MDBCardBody>
//                 </MDBCard>
//                 <div className="d-flex justify-content-center mt-3">
//                   <Button
//                     variant="primary"
//                     className="w-50"
//                     onClick={handleOnSubmit}
//                     style={{
//                       transition: "transform 0.2s",
//                       marginLeft: "0.5px",
//                     }}
//                     onMouseEnter={(e) => {
//                       e.target.style.transform = "scale(1.05)";
//                     }}
//                     onMouseLeave={(e) => {
//                       e.target.style.transform = "scale(1)";
//                     }}
//                   >
//                     Save Changes
//                   </Button>
//                 </div>
//               </MDBCol>
//             </MDBRow>
//           </MDBContainer>
//         </section>
//       )}
//     </CommonLayout>
//   );
// }

// export default EditProfile;


//By Chetan

import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBInput,
  MDBCardImage,
} from "mdb-react-ui-kit";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../../routes/constant";
import { Button, Spinner } from "react-bootstrap";
import { getConfig } from "@testing-library/react";

function EditProfile() {
  const [profileData, setProfileData] = useState({
    fullName: "",
    email: "",
    mobile: "",
  });
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getProfileDetails(
        {},
        getAuthConfig(),
        navigate
      );
      if (response.success) {
        setProfileData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await allAPiServicesCall.editProfile(
        profileData,
        getAuthConfig(),
        navigate
      );
      if (response.success) {
        navigate(ROUTE_CONSTANT.AUTH.SHOW_PROFILE);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const handleImageChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setImage(objectURL); 
    console.log("image yurlmnkkkkk",objectURL)
    console.log("image yurlmnkkkkcvczvvvvdvcvk",file)
    uploadImageToServer(file)
    }
  };

  const uploadImageToServer = async (file) => {
    console.log('image', file)

    if (!file) {
      return console.log("no image selected")
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('image', file);
      console.log('formdata',formData)
      const response = await allAPiServicesCall.editProfilePicure(
        formData,
        {
          headers: {
            Authorization: `${localStorage.getItem('auth-token')}`,
            "Content-Type": "multipart/form-data",
          },
        },
        navigate
      );
      if (response.success) {
        navigate(ROUTE_CONSTANT.AUTH.SHOW_PROFILE);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }



  return (
    <CommonLayout>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <section style={{ backgroundColor: "#f8f9fa", paddingTop: "30px" }}>
          <MDBContainer className="py-5">
            <MDBRow>
              {/* Profile Image */}
              <MDBCol md="4" xs="12" className="mb-4">
                <MDBCard className="mb-4 shadow-sm">
                  <MDBCardBody className="text-center">
                    <MDBCardImage
                      src={image || profileData.avatar} // Display preview if available
                      alt="avatar"
                      className="rounded-circle mb-3"
                      style={{ width: '150px', border: '2px solid #007bff' }}
                      fluid
                    />
                    <h5 className="mt-2">Admin</h5>
                    <p className="text-muted mb-1">
                      <a
                        href="https://lyricsweb.com"
                        style={{ textDecoration: 'none', color: '#007bff' }}
                      >
                        Owner at Lyricsweb.com
                      </a>
                    </p>
                    <div className="file-upload-container mt-3">
                      <label htmlFor="file-upload" className="custom-file-upload btn btn-primary">
                        Choose File
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }} // Hide the default input
                      />
                      <small className="text-muted d-block mt-2">
                        Supported formats: JPG, PNG, GIF
                      </small>
                    </div>

                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              {/* Profile Edit Form */}
              <MDBCol md="8" xs="12">
                <MDBCard className="shadow-sm mb-4">
                  <MDBCardBody>
                    <form onSubmit={handleOnSubmit}>
                      <MDBRow className="align-items-center mb-4">
                        <MDBCol sm="3" xs="12">
                          <MDBCardText>Full Name</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9" xs="12">
                          <MDBInput
                            name="fullName"
                            value={profileData.fullName}
                            onChange={handleChange}
                            placeholder="Enter full name"
                            required
                          />
                        </MDBCol>
                      </MDBRow>

                      <hr />

                      <MDBRow className="align-items-center mb-4">
                        <MDBCol sm="3" xs="12">
                          <MDBCardText>Email</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9" xs="12">
                          <MDBInput
                            type="email"
                            name="email"
                            value={profileData.email}
                            readOnly
                            onChange={handleChange}
                            placeholder="Email"
                            required
                          />
                        </MDBCol>
                      </MDBRow>

                      <hr />

                      <MDBRow className="align-items-center mb-4">
                        <MDBCol sm="3" xs="12">
                          <MDBCardText>Mobile</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9" xs="12">
                          <MDBInput
                            name="mobile"
                            value={profileData.mobile}
                            onChange={handleChange}
                            placeholder="Enter mobile number"
                            required
                          />
                        </MDBCol>
                      </MDBRow>
                    </form>
                  </MDBCardBody>
                </MDBCard>

                {/* Save Button */}
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    variant="primary"
                    className="w-75"
                    onClick={handleOnSubmit}
                    style={{
                      transition: "transform 0.2s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  >
                    Save Changes
                  </Button>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      )}
    </CommonLayout>
  );
}

export default EditProfile;

