// import React, { useState, useEffect } from 'react';
// import CommonLayout from '../../../layouts/CommonLayout';
// import { Modal, Button } from 'react-bootstrap';  // Importing Bootstrap's Modal and Button components
// import { allAPiServicesCall } from '../../../services/apiServices';
// import { getAuthConfig } from '../../../services/apiUtils';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';

// const LyricsModal = ({ isOpen, lyricsDetails, closeModal }) => {
//     const navigate = useNavigate();
//     if (!isOpen) return null;
//     const handleApproveOrReject = async (lyricsId, status) => {
//         try {
//             const payload = {
//                 contributionId: lyricsId,
//                 status: status
//             }
//             const responseStatus = await allAPiServicesCall.approveOrrejectLyrics(payload, getAuthConfig(), navigate);
//             if (responseStatus === 200 || responseStatus === 404) {
//                 closeModal()
//             }
//         } catch (error) {
//             toast.error(error.message);
//         }
//     }

//     return (
//         <Modal show={isOpen} onHide={closeModal} size="lg" centered>
//             <Modal.Header closeButton>
//                 <Modal.Title>{lyricsDetails.songName}</Modal.Title>
//             </Modal.Header>
//             <Modal.Body className='text-center'>
//                 <p><strong>Song:</strong> {lyricsDetails.songName}</p>
//                 <p><strong>Lyrics:</strong></p>
//                 <p >
//                     {lyricsDetails.lyrics.split("\\n").map((line, index) => (
//                         <React.Fragment key={index}>
//                             {line}
//                             <br />
//                         </React.Fragment>
//                     ))}
//                 </p>
//                 <p><strong>Language:</strong> {lyricsDetails.language}</p>
//                 <p><strong>Release Year:</strong> {lyricsDetails.releaseYear}</p>
//                 <p><strong>Genres:</strong> {lyricsDetails.genres.join(', ')}</p>
//                 <p><strong>Performed By:</strong> {lyricsDetails.performedBy}</p>
//                 <p><strong>Status:</strong> {lyricsDetails.status}</p>
//                 <p><strong>Artist Bio:</strong> {lyricsDetails.artistBio}</p>
//                 <p>
//                     <a href={lyricsDetails.youtubeURL} target="_blank" rel="noopener noreferrer">Watch on YouTube</a>
//                 </p>
//                 <p>
//                     <a href={lyricsDetails.artistWebsite} target="_blank" rel="noopener noreferrer">Artist Website</a>
//                 </p>
//             </Modal.Body>
//             <Modal.Footer>
//                 {lyricsDetails.status === "Pending" && (
//                     <>
//                         <Button variant="primary" onClick={() => handleApproveOrReject(lyricsDetails._id, "approved")}>
//                             Approve
//                         </Button>
//                         <Button variant="danger" onClick={() => handleApproveOrReject(lyricsDetails._id, "rejected")} >
//                             Reject
//                         </Button>
//                     </>
//                 )}
//                 <Button variant="secondary" onClick={closeModal}>
//                     Close
//                 </Button>
//             </Modal.Footer>

//         </Modal >
//     );
// };


// const ContributeToLyricsList = () => {
//     const [contributions, setContributions] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [modalOpen, setModalOpen] = useState(false);
//     const [selectedLyrics, setSelectedLyrics] = useState(null);

//     const fetchLyrics = async () => {
//         try {
//             const fetchContributions = await allAPiServicesCall.getAllContributeToLyrics(getAuthConfig());
//             if (fetchContributions.status === 200) {

//                 setContributions(fetchContributions.data);
//             } else {
//                 setContributions([]);
//             }
//         } catch (error) {
//             console.error("Error fetching contributions:", error);
//             setError('Error fetching contributions');
//         } finally {
//             setLoading(false);
//         }
//     };


//     useEffect(() => {
//         fetchLyrics()
//     }, []);

//     const fetchLyricsDetails = async (lyricsId) => {
//         try {
//             const response = await allAPiServicesCall.getLyricsById(getAuthConfig(), lyricsId)
//             setSelectedLyrics(response.data);
//             setModalOpen(true);
//         } catch (error) {
//             setError('Error fetching lyrics details');
//         }
//     };

//     if (loading) return <div>Loading...</div>;
//     if (error) return <div>{error}</div>;

//     return (
//         <CommonLayout>
//             <div className="container mt-5 text-center pt-5">
//                 <h2>Contributions List</h2>
//                 <table className="table table-striped table-responsive">
//                     <thead>
//                         <tr>
//                             <th>Song Name</th>
//                             <th>Lyrics</th>
//                             <th>Language</th>
//                             <th>Release Year</th>
//                             <th>Genres</th>
//                             <th>Performed By</th>
//                             <th>Added By</th>
//                             <th>Status</th>
//                             <th>Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {contributions.map(contribution => (
//                             <tr key={contribution._id}>
//                                 <td>{contribution.songName}</td>
//                                 <td>{contribution.lyrics.substring(0, 50)}...</td>  {/* Show first 50 characters of lyrics */}
//                                 <td>{contribution.language}</td>
//                                 <td>{contribution.releaseYear}</td>
//                                 <td>{contribution.genres.join(', ')}</td>
//                                 <td>{contribution.performedBy}</td>
//                                 <td>{contribution.user_data.username}</td>
//                                 <td
//                                     style={{
//                                         color:
//                                             contribution.status === "Approved"
//                                                 ? "green"
//                                                 : contribution.status === "Rejected"
//                                                     ? "yellow"
//                                                     : "black",
//                                     }}
//                                 >
//                                     {contribution.status}
//                                 </td>

//                                 <td>
//                                     <button className="btn btn-info btn-sm" onClick={() => fetchLyricsDetails(contribution._id)}>View</button>
//                                     <button className="btn btn-danger btn-sm ml-2">Delete</button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>

//             {/* Modal Component */}
//             <LyricsModal
//                 isOpen={modalOpen}
//                 lyricsDetails={selectedLyrics}
//                 closeModal={() => setModalOpen(false)}
//             />
//         </CommonLayout>
//     );
// };

// export default ContributeToLyricsList;


import React, { useState, useEffect } from 'react';
import CommonLayout from '../../../layouts/CommonLayout';
import { Modal, Button } from 'react-bootstrap'; // Importing Bootstrap's Modal and Button components
import { allAPiServicesCall } from '../../../services/apiServices';
import { getAuthConfig } from '../../../services/apiUtils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './ContributeToLyricsList.css'



const ContributeToLyricsList = () => {
    const [contributions, setContributions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLyrics, setSelectedLyrics] = useState(null);
    const navigate = useNavigate();

    const fetchLyrics = async () => {
        try {
            const fetchContributions = await allAPiServicesCall.getAllContributeToLyrics(getAuthConfig());
            if (fetchContributions.status === 200) {
                setContributions(fetchContributions.data);
            } else {
                setContributions([]);
            }
        } catch (error) {
            console.error("Error fetching contributions:", error);
            setError('Error fetching contributions');
        } finally {
            setLoading(false);
        }
    };

    const fetchLyricsDetails = async (lyricsId) => {
        try {
            const response = await allAPiServicesCall.getLyricsById(getAuthConfig(), lyricsId);
            setSelectedLyrics(response.data);
            setModalOpen(true);
        } catch (error) {
            setError('Error fetching lyrics details');
        }
    };

    const handleApproveOrReject = async (lyricsId, status) => {
        try {
            const payload = {
                contributionId: lyricsId,
                status: status
            };
            const responseStatus = await allAPiServicesCall.approveOrrejectLyrics(payload, getAuthConfig(), navigate);
            setModalOpen(false);
            fetchLyrics(); // Refresh the contributions list

        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchLyrics();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <CommonLayout>
          <div className="container contricon">
            <div className='row'>
              <div className='col-md-12 col-sm-12'>
  <h2
    style={{
      textAlign: "center",
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "2.8rem",
      fontWeight: "600",
      letterSpacing: "1.5px",
      textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
      marginBottom: "5%",
      background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
      WebkitBackgroundClip: "text",
    }}
  >
    Contributions List
  </h2>
  {/* <div className="table-responsive">
    <table className="table table-hover ">
      <thead>
        <tr>
          <th >Song Name</th>
          <th >Lyrics</th>
          <th>Language</th>
          <th>Release Year</th>
          <th>Genres</th>
          <th>Performed By</th>
          <th >Added By</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {contributions.map((contribution) => (
          <tr key={contribution._id}>
            <td>{contribution.songName}</td>
            <td>{contribution.lyrics.substring(0, 50)}...</td>
            <td>{contribution.language}</td>
            <td>{contribution.releaseYear}</td>
            <td>{contribution.genres.join(", ")}</td>
            <td>{contribution.performedBy}</td>
            <td>{contribution.user_data.username}</td>
            <td
              style={{
                color:
                  contribution.status === "Approved"
                    ? "green"
                    : contribution.status === "Rejected"
                    ? "yellow"
                    : "black",
              }}
            >
              {contribution.status}
            </td>
            <td>
              <div className="d-flex gap-3">
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => fetchLyricsDetails(contribution._id)}
                >
                  View
                </button>
                <button className="btn btn-danger btn-sm ml-2">Delete</button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div> */}
  </div>
</div>
<div className="table-responsive">
    <table className="table table-hover">
        <thead>
            <tr>
                <th>Song Name</th>
                <th>Lyrics</th>
                <th>Language</th>
                <th>Release Year</th>
                <th>Genres</th>
                <th>Performed By</th>
                <th>Added By</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {contributions.map((contribution) => (
                <tr key={contribution._id}>
                    <td>{contribution.songName}</td>
                    <td>{contribution.lyrics.substring(0, 50)}...</td>
                    <td>{contribution.language}</td>
                    <td>{contribution.releaseYear}</td>
                    <td>{contribution.genres.join(", ")}</td>
                    <td>{contribution.performedBy}</td>
                    <td>{contribution.user_data.username}</td>
                    <td
                        style={{
                            color:
                                contribution.status === "Approved"
                                    ? "green"
                                    : contribution.status === "Rejected"
                                    ? "yellow"
                                    : "black",
                        }}
                    >
                        {contribution.status}
                    </td>
                    <td>
                        <div className="d-flex gap-3">
                            <button
                                className="btn btn-info btn-sm"
                                onClick={() => fetchLyricsDetails(contribution._id)}
                            >
                                View
                            </button>
                            <button className="btn btn-danger btn-sm ml-2">Delete</button>
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

</div>


            {/* Modal Component */}
            {modalOpen && selectedLyrics && (
                <Modal show={modalOpen} onHide={() => setModalOpen(false)} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedLyrics.songName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <p><strong>Song:</strong> {selectedLyrics.songName}</p>
                        <p><strong>Lyrics:</strong></p>
                        <p>
                            {selectedLyrics.lyrics.split("\\n").map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                        <p><strong>Language:</strong> {selectedLyrics.language}</p>
                        <p><strong>Release Year:</strong> {selectedLyrics.releaseYear}</p>
                        <p><strong>Genres:</strong> {selectedLyrics.genres.join(', ')}</p>
                        <p><strong>Performed By:</strong> {selectedLyrics.performedBy}</p>
                        <p><strong>Status:</strong> {selectedLyrics.status}</p>
                        <p><strong>Artist Bio:</strong> {selectedLyrics.artistBio}</p>
                        <p>
                            <a href={selectedLyrics.youtubeURL} target="_blank" rel="noopener noreferrer">Watch on YouTube</a>
                        </p>
                        <p>
                            <a href={selectedLyrics.artistWebsite} target="_blank" rel="noopener noreferrer">Artist Website</a>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        {selectedLyrics.status === "Pending" && (
                            < >
                                <Button variant="primary" onClick={() => handleApproveOrReject(selectedLyrics._id, "approved")}>
                                    Approve
                                </Button>
                                <Button variant="danger" onClick={() => handleApproveOrReject(selectedLyrics._id, "rejected")}>
                                    Reject
                                </Button>
                            </>
                        )}
                        <Button variant="secondary" onClick={() => setModalOpen(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </CommonLayout>
    );
};

export default ContributeToLyricsList;
