// import React, { useEffect, useState } from "react";
// import CommonLayout from "../../../layouts/CommonLayout";
// import { allAPiServicesCall } from "../../../services/apiServices";
// import { getAuthConfig } from "../../../services/apiUtils";
// import { Button, ButtonGroup, InputGroup, Form } from "react-bootstrap";
// import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"; // Import ConfirmationModal
// import { useNavigate } from "react-router-dom";

// function Playlist() {
//   const [playlists, setPlaylists] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [showDeleteModal, setShowDeleteModal] = useState(false); // State for modal visibility
//   const [playlistToDelete, setPlaylistToDelete] = useState(null); // State to store the playlist to delete
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchAllAdminPlaylist = async () => {
//       setLoading(true);

//       try {
//         let response = await allAPiServicesCall.getAllAdminPlaylist(
//           {},
//           getAuthConfig()
//         );

//         if (Array.isArray(response.data)) {
//           setPlaylists(response.data);
//         } else {
//           console.warn("No data found or data is not an array");
//           setError("No data found or data is not an array");
//         }
//       } catch (error) {
//         console.error("Error fetching playlists:", error);
//         setError("Error fetching playlists");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAllAdminPlaylist();
//   }, []);

//   const handleCreatePlaylist = () => {
//     navigate("/create_playlist");
//   };

//   const handleDeletePlaylist = (playlistId) => {
//     setPlaylistToDelete(playlistId);
//     setShowDeleteModal(true);
//   };

//   const confirmDelete = async () => {
//     if (!playlistToDelete) return;

//     try {
//       await allAPiServicesCall.deleteAdminPlaylist({ playlist_id: playlistToDelete }, getAuthConfig());

//       setPlaylists(playlists.filter((playlist) => playlist._id !== playlistToDelete));

//       // Close the modal
//       setShowDeleteModal(false);
//       setPlaylistToDelete(null);
//     } catch (error) {
//       console.error("Error deleting playlist:", error);
//     }
//   };

//   const handleSearchTermChange = ()=> {

//   }

//   return (
//     <CommonLayout>
//       <div className="container">
//         <h3  style={{
//             textAlign: "center",
//             fontFamily: "'Montserrat', sans-serif",
//             fontSize: "2.8rem",
//             fontWeight: "600",
//             letterSpacing: "1.5px",
//             textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
//             marginBottom: "5%",
//             background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
//             WebkitBackgroundClip: "text",
//           }}>ALL PLAYLIST</h3>

// <InputGroup className="mb-3">
//           <Form.Control
//             className="search-input"
//             placeholder="Search for a song..."
//             value={searchTerm}
    
//           />
//         </InputGroup>


//         {loading ? (
//           <p>Loading...</p>
//         ) : error ? (
//           <p>{error}</p>
//         ) : playlists.length === 0 ? (
//           <div style={styles.noPlaylists}>
//             <p>No playlists found. You can create a new playlist.</p>
//             <Button variant="primary" onClick={handleCreatePlaylist}>
//               Create Playlist
//             </Button>
//           </div>
//         ) : (
//           <div style={styles.cardContainer}>
//             {playlists.map((playlist) => (
//               <div key={playlist.id} style={styles.card}>
//                 <img
//                   crossorigin="anonymous"
//                   src={playlist.playlist_image}
//                   alt={playlist.title}
//                   style={styles.cardImage}
//                 />
//                 <div style={styles.cardContent}>
//                   <h3 style={styles.cardTitle}>{playlist.playlist_name}</h3>
//                   <p style={styles.totalSongs}>
//                     Total Songs: {playlist.song_count}
//                   </p>

//                   <ButtonGroup style={styles.buttonGroup}>
//                     <Button
//                       variant="primary"
//                       onClick={() => {
//                         navigate(`/playlist/${playlist._id}`);
//                       }}
//                     >
//                       View
//                     </Button>
//                     <Button
//                       variant="info"
//                       onClick={() => {
//                         navigate(`/playlist/${playlist._id}`);
//                       }}
//                     >
//                       Edit
//                     </Button>
//                     <Button
//                       variant="danger"
//                       onClick={() => handleDeletePlaylist(playlist._id)} // Trigger modal on delete
//                     >
//                       Delete
//                     </Button>
//                   </ButtonGroup>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       {/* Confirmation Modal for Deleting Playlist */}
//       <ConfirmationModal
//         show={showDeleteModal}
//         onHide={() => setShowDeleteModal(false)}
//         onConfirm={confirmDelete} // Trigger delete confirmation
//         message="Are you sure you want to delete this playlist?"
//       />
//     </CommonLayout>
//   );
// }

// const styles = {
//   container: {
//     padding: "20px",
//     maxWidth: "800px",
//     margin: "50px auto",
//   },
//   heading: {
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   cardContainer: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "space-between",
//     gap: "20px",
//   },
//   card: {
//     flex: "1 1 calc(33% - 20px)",
//     backgroundColor: "#fff",
//     borderRadius: "10px",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//     overflow: "hidden",
//     textAlign: "center",
//   },
//   cardImage: {
//     width: "100%",
//     height: "50%",
//   },
//   cardContent: {
//     padding: "15px",
//   },
//   cardTitle: {
//     fontSize: "1.2em",
//     margin: "10px 0",
//   },
//   totalSongs: {
//     fontSize: "0.8em",
//     margin: "10px 0",
//   },
//   buttonGroup: {
//     display: "flex",
//     justifyContent: "center",
//     marginTop: "10px",
//   },
//   noPlaylists: {
//     textAlign: "center",
//     padding: "20px",
//     backgroundColor: "#f8f8f8",
//     borderRadius: "10px",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//   },
// };

// export default Playlist;




// import React, { useEffect, useState } from "react";
// import CommonLayout from "../../../layouts/CommonLayout";
// import { allAPiServicesCall } from "../../../services/apiServices";
// import { getAuthConfig } from "../../../services/apiUtils";
// import { Button, ButtonGroup, InputGroup, Form } from "react-bootstrap";
// import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
// import { useNavigate } from "react-router-dom";

// function Playlist() {
//   const [playlists, setPlaylists] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [playlistToDelete, setPlaylistToDelete] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchAllAdminPlaylist = async () => {
//       setLoading(true);

//       try {
//         let response = await allAPiServicesCall.getAllAdminPlaylist(
//           {},
//           getAuthConfig()
//         );

//         if (Array.isArray(response.data)) {
//           setPlaylists(response.data);
//         } else {
//           console.warn("No data found or data is not an array");
//           setError("No data found or data is not an array");
//         }
//       } catch (error) {
//         console.error("Error fetching playlists:", error);
//         setError("Error fetching playlists");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAllAdminPlaylist();
//   }, []);

//   const handleCreatePlaylist = () => {
//     navigate("/create_playlist");
//   };

//   const handleDeletePlaylist = (playlistId) => {
//     setPlaylistToDelete(playlistId);
//     setShowDeleteModal(true);
//   };

//   const confirmDelete = async () => {
//     if (!playlistToDelete) return;

//     try {
//       await allAPiServicesCall.deleteAdminPlaylist(
//         { playlist_id: playlistToDelete },
//         getAuthConfig()
//       );

//       setPlaylists(playlists.filter((playlist) => playlist._id !== playlistToDelete));

//       setShowDeleteModal(false);
//       setPlaylistToDelete(null);
//     } catch (error) {
//       console.error("Error deleting playlist:", error);
//     }
//   };

//   const handleSearchTermChange = (e) => {
//     setSearchTerm(e.target.value.toLowerCase());
//   };

//   const filteredPlaylists = playlists.filter((playlist) =>
//     playlist.playlist_name.toLowerCase().includes(searchTerm)
//   );

//   return (
//     <CommonLayout>
//       <div className="container">
//         <h3
//           style={{
//             textAlign: "center",
//             fontFamily: "'Montserrat', sans-serif",
//             fontSize: "2.8rem",
//             fontWeight: "600",
//             letterSpacing: "1.5px",
//             textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
//             marginBottom: "5%",
//             background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
//             WebkitBackgroundClip: "text",
//           }}
//         >
//           ALL PLAYLIST
//         </h3>

//         <Form.Group controlId="searchAlbum">
//             <Form.Control
//               type="text"
//               placeholder="Search for a playlist..."
//               value={searchTerm}
//               onChange={handleSearchTermChange}
//               className="search-album-input mb-4"
//             />
//           </Form.Group>

   

//         {loading ? (
//           <p>Loading...</p>
//         ) : error ? (
//           <p>{error}</p>
//         ) : filteredPlaylists.length === 0 ? (
//           <div style={styles.noPlaylists}>
//             <p>No playlists found. You can create a new playlist.</p>
//             <Button variant="primary" onClick={handleCreatePlaylist}>
//               Create Playlist
//             </Button>
//           </div>
//         ) : (
//           <div style={styles.cardContainer}  >
//             {filteredPlaylists.map((playlist) => (
//               <div key={playlist._id} style={styles.card} className=" w-75 " >
//                 <img 
//                 className=" p-2"
//                   crossOrigin="anonymous"
//                   src={playlist.playlist_image}
//                   alt={playlist.title}
//                   style={styles.cardImage}
//                 />
//                 <div style={styles.cardContent}>
//                   <h3 style={styles.cardTitle}>{playlist.playlist_name}</h3>
//                   <p style={styles.totalSongs}>
//                     Total Songs: {playlist.song_count}
//                   </p>

//                   <ButtonGroup style={styles.buttonGroup}>
//                     <Button
//                       variant="primary"
//                       onClick={() => {
//                         navigate(`/playlist/${playlist._id}`);
//                       }}
//                     >
//                       View
//                     </Button>
//                     <Button
//                       variant="info"
//                       onClick={() => {
//                         navigate(`/playlist/${playlist._id}`);
//                       }}
//                     >
//                       Edit
//                     </Button>
//                     <Button
//                       variant="danger"
//                       onClick={() => handleDeletePlaylist(playlist._id)}
//                     >
//                       Delete
//                     </Button>
//                   </ButtonGroup>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <ConfirmationModal
//         show={showDeleteModal}
//         onHide={() => setShowDeleteModal(false)}
//         onConfirm={confirmDelete}
//         message="Are you sure you want to delete this playlist?"
//       />
//     </CommonLayout>
//   );
// }

// const styles = {
//   container: {
//     padding: "20px",
//     maxWidth: "800px",
//     margin: "50px auto",
//   },
//   heading: {
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   cardContainer: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "space-between",
//     gap: "34px",
//   },
//   // card: {
//   //   flex: "1 1 calc(33% - 20px)",
//   //   backgroundColor: "#fff",
//   //   borderRadius: "10px",
//   //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//   //   overflow: "hidden",
//   //   textAlign: "center",
//   // },

//   card: {
//     flex: "1 1 calc(33% - 20px)",
//     backgroundColor: "#fff",
//     borderRadius: "10px",
//     boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)", // Increased shadow for a more prominent effect
//     backdropFilter: "blur(10px)", // Adds blur effect to the card's background
//     overflow: "hidden",
//     textAlign: "center",
//   }
// ,  
// "card:hover": {
//   transform: "translateY(-10px)", // Moves the card up by 10px
//   boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
// },

//   cardImage: {
//     width: "100%",
//     height: "50%",
//   },
//   cardContent: {
//     padding: "15px",
//   },
//   cardTitle: {
//     fontSize: "1.2em",
//     margin: "10px 0",
//   },
//   totalSongs: {
//     fontSize: "0.8em",
//     margin: "10px 0",
//   },
//   buttonGroup: {
//     display: "flex",
//     justifyContent: "center",
//     marginTop: "10px",
//   },
//   noPlaylists: {
//     textAlign: "center",
//     padding: "20px",
//     backgroundColor: "#f8f8f8",
//     borderRadius: "10px",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//   },
// };

// export default Playlist;



import React, { useEffect, useState } from "react";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import './Playlist.css'; // Assuming you save your CSS styles in this file

import { toast } from "react-toastify";
import EditPlaylistModal from "../../../components/Model/EditPlaylistModel"

function Playlist() {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [playlistToDelete, setPlaylistToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [playlistEditData, setPlaylistEditData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllAdminPlaylist = async () => {
      setLoading(true);

      try {
        let response = await allAPiServicesCall.getAllAdminPlaylist(
          {},
          getAuthConfig()
        );
       

        if (Array.isArray(response.data)) {
          setPlaylists(response.data);
        } else {
          console.warn("No data found or data is not an array");
          setError("No data found or data is not an array");
        }
      } catch (error) {
        console.error("Error fetching playlists:", error);
        setError("Error fetching playlists");
      } finally {
        setLoading(false);
      }
    };

    fetchAllAdminPlaylist();
  }, []);

  const handleCreatePlaylist = () => {
    navigate("/create_playlist");
  };

  const handleDeletePlaylist = (playlistId) => {
    setPlaylistToDelete(playlistId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!playlistToDelete) return;

    try {
      await allAPiServicesCall.deleteAdminPlaylist(
        { playlist_id: playlistToDelete },
        getAuthConfig()
      );

      setPlaylists(playlists.filter((playlist) => playlist._id !== playlistToDelete));

      setShowDeleteModal(false);
      setPlaylistToDelete(null);
    } catch (error) {
      console.error("Error deleting playlist:", error);
    }
  };

  const handleEditPlayList = (playlistID) => {
    setShowEditModal(true)
    setPlaylistEditData(playlistID)
  }

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

 
  const filteredPlaylists = playlists.filter((playlist) =>
    playlist.playlist_name.toLowerCase().includes(searchTerm)
  );

  return (
    <CommonLayout>
      <div className="container">
        <h3   style={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.8rem",
            fontWeight: "600",
            letterSpacing: "1.5px",
            textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
            marginBottom: "5%",
            background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
            WebkitBackgroundClip: "text",
          }} className="playlist-heading">ALL PLAYLIST</h3>

        <Form.Group controlId="searchAlbum">
          <Form.Control
            type="text"
            placeholder="Search for a playlist..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="search-album-input mb-4"
          />
        </Form.Group>

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : filteredPlaylists.length === 0 ? (
          <div className="no-playlists">
            <p>No playlists found. You can create a new playlist.</p>
            <Button variant="primary" onClick={handleCreatePlaylist}>
              Create Playlist
            </Button>
          </div>
        ) : (
          <div className="playlist-card-container">
            {filteredPlaylists.map((playlist) => (
              <div key={playlist._id} className="playlist-card">
                <img
                  className="playlist-card-image"
           
                  src={playlist.playlist_image}
                  alt={playlist.title}
                />
              
                {console.log(playlist.playlist_image,"playlist image url")}
                <div className="playlist-card-content">
                  <h3 className="playlist-card-title text-center">{playlist.playlist_name}</h3>
                  <p className="playlist-card-description text-center">
                    Total Songs: {playlist.song_count}
                  </p>

                  <ButtonGroup className="playlist-button-group">
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(`/playlist/${playlist._id}`);
                      }}
                    >
                      View
                    </Button>
                    <Button
                      variant="info"
                      onClick={() => handleEditPlayList(playlist)}
                    >
                      Edit
                    </Button>
                
                    <Button
                      variant="danger"
                      onClick={() => handleDeletePlaylist(playlist._id)}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <ConfirmationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this playlist?"
      />

      

      <EditPlaylistModal
       show={showEditModal}
       handleClose={() => setShowEditModal(false)}
       playlistDetails={playlistEditData} 
      
      />

    </CommonLayout>

    
  );
}

export default Playlist;

