import React, { useEffect, useState } from "react";
import { Card, Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"; // Import the ConfirmationModal component
import { ROUTE_CONSTANT } from "../../../routes/constant";
import { handleApiError } from "../../../utils/authHelpers";

const PopularSongs = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [songsData, setSongsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // State for showing the confirmation modal
  const [songToDelete, setSongToDelete] = useState(null); // State to hold the song ID for deletion

  // Fetch popular songs
  const fetchPopularSongs = async () => {
    try {
      const response = await allAPiServicesCall.getPopularSongs(
        {},
        getAuthConfig()
      );
      setSongsData(response.data.songs || []);
    } catch (error) {
      console.error("Error fetching popular songs:", error);
      toast.error("Failed to fetch popular songs");
    } finally {
      setLoading(false);
    }
  };

  // Debouncing search term
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.trim()) {
        fetchSearchResults(searchTerm);
      } else {
        setSearchResults([]); // Clear results if searchTerm is empty
      }
    }, 500); // 500ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Fetch search results
  const fetchSearchResults = async (song_name) => {
    setLoading(true);
    try {
      let response = await allAPiServicesCall.getSearchSong(
        { song_name },
        getAuthConfig()
      );

      const searchList = response.data || [];
      if (searchList.length > 0) {
        setSearchResults(searchList);
      } else {
        setSearchResults([]);
        toast.info("No results found");
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      toast.error("Failed to fetch search results");
    } finally {
      setLoading(false);
    }
  };

  const handleAddToMostPopular = async (song_id) => {
    try {
      setLoading(true);
      const popularSong = await allAPiServicesCall.addMostPopularSongs({ song_id }, getAuthConfig());

      if (popularSong && popularSong.success) {
        toast.success(popularSong.message);
        setSearchTerm('');
        fetchPopularSongs();
      }

    } catch (error) {
      toast.error('An error occurred while adding the song. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Open modal for delete confirmation
  const handleDelete = (songId) => {
    setSongToDelete(songId); // Set the song ID for deletion
    setShowModal(true); // Show the confirmation modal
  };

  // Confirm deletion of the song
  const confirmDelete = async () => {
    try {
      const updatedSongs = await allAPiServicesCall.deleteMostPopularSongs({ id: songToDelete }, getAuthConfig());
      if (updatedSongs && updatedSongs.success) {
        toast.success(updatedSongs.message);
        fetchPopularSongs();
      } else {
        toast.error(updatedSongs.message);
      }
    } catch (error) {
      toast.error('An error occurred while deleting the song. Please try again.');
    } finally {
      setShowModal(false); // Hide the modal
    }
  };

  // Close the confirmation modal
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchPopularSongs();
  }, []);

  return (
    <CommonLayout>
      <div className="container ">
        <h3  style={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.8rem",
            fontWeight: "600",
            letterSpacing: "1.5px",
            textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
            marginBottom: "5%",
            background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
            WebkitBackgroundClip: "text",
          }}> MOST POPULAR SONGS</h3>

        <div className="mb-4">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Search for a song..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </div>

        {loading ? (
          <div className="text-center">Loading...</div>
        ) : searchResults.length > 0 ? (
          <div>
            <h4>Search Results</h4>
            <Row className="justify-content-center">
              {searchResults.map((song) => (
             
                <Col key={song.song_id} xs={12} sm={6} md={4} lg={3} className="mb-2">
                  <Card className="song-card">
                    <Card.Img
                      variant="top"
                      src={song.album.image_link}
                      alt={song.song_name}
                    />
                    
                    <Card.Body>
                      <Card.Title className="text-truncate" style={{ maxHeight: '3em' }}>{song.song_name}</Card.Title>
                    </Card.Body>
                    <Card.Footer className="add-card-footer">
                      <Button
                        variant="success"
                        onClick={() => handleAddToMostPopular(song.song_id)}
                      >
                        Add to Most Popular
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ) : songsData.length === 0 ? (
          <div className="text-center">No recommended songs available.</div>
        ) : (
          <div>
        <Row className="justify-content-center">
  {songsData
    .filter((song) =>
      song.song_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .map((song) => (
      <Col 
        key={song.song_id} 
        xs={12}  // Full width on extra-small screens
        sm={6}   // 2 cards per row on small screens
        md={4}   // 3 cards per row on medium screens
        lg={3}   // 4 cards per row on large screens
        className="d-flex justify-content-center"
      >
        <Card className="song-card my-3" style={{ width: "100%", maxWidth: "300px" }}>
          <Card.Img
            variant="top"
            src={song.song_image_link}
            alt={song.song_name}
            style={{ height: "150px", objectFit: "cover" }}
          />
          <Card.Body>
            <Card.Title>{song.song_name}</Card.Title>
            <Card.Text>
              <strong>Artist Name:</strong> {song.artist?.artist_name || "Unknown"}
              <br />
              <strong>Album Name:</strong> 
              <ReadMoreText text={song.album?.album_name || "Unknown"} />
            </Card.Text>

            <div className="mt-2">
              <Button
                variant="danger"
                onClick={() => handleDelete(song._id)}
              >
                Delete
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    ))}
</Row>

          </div>
        )}
      </div>

      {/* Confirmation modal for deleting a song */}
      <ConfirmationModal
        show={showModal}
        handleClose={closeModal}
        handleConfirm={confirmDelete}
        message="Are you sure you want to delete this song from the popular list?"
      />
    </CommonLayout>
  );
};


const ReadMoreText = ({ text, maxLength = 15, isTitle = false, minimumLenth = 11 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const truncatedText =
    text.length > maxLength && !isExpanded
      ? `${text.slice(minimumLenth, maxLength)}...`
      : text;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <span>
      {truncatedText}
      {text.length > maxLength && (
        <span
          style={{
            color: "blue",
            cursor: "pointer",
            marginLeft: "5px",
            fontSize: isTitle ? "0.85rem" : "0.8rem",
          }}
          onClick={toggleExpanded}
        >
          {isExpanded ? "read less" : "Read more"}
        </span>
      )}
    </span>
  );
};



export default PopularSongs;
